import {apiRequest} from "../service/service";

class Api {
    submitPersonalData = (params = {}) => {
        const url = `personal-data`;
        const config = {
            method:'post',
            url:url,
            params,
        };
        return apiRequest(config);
    };
    sendCode = (params = {}) => {
        const url = `send-code`;
        const config = {
            method:'post',
            url:url,
            params,
        };
        return apiRequest(config);
    };
    sendEmail = (params = {}) => {
        const url = `send-code`;
        const config = {
            method:'post',
            url:url,
            params,
        };
        return apiRequest(config);
    };
    verifyCode = (params = {}) => {
        const url = `verify`;
        const config = {
            method:'post',
            url:url,
            params,
        };
        return apiRequest(config);
    };
    verifyEmail = (params = {}) => {
        const url = `verify`;
        const config = {
            method:'post',
            url:url,
            params,
        };
        return apiRequest(config);
    };
    submitAddress = (params = {}) => {
        const url = `address`;
        const config = {
            method:'post',
            url:url,
            params,
        };
        return apiRequest(config);
    };
    submitApplication = (params = {}) => {
        const url = `application`;
        const config = {
            method:'post',
            url:url,
            params,
        };
        return apiRequest(config);
    };
    getCities = (params = {}) => {
        const url = `application`;
        const config = {
            method:'post',
            url:url,
            params,
        };
        return apiRequest(config);
    };
    uploadDocuments = (params = {}) => {
        const url = `documents`;
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            },
            method:'post',
            url:url,
            data:params,
        };
        return apiRequest(config);
    };
}

export default Api;
