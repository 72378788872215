import axios from 'axios';

const API_URL = process.env.REACT_APP_API_PATH;

const instance = axios.create({
    baseURL: API_URL
});



export const apiRequest = (config) => {
    return instance(config)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            if(!error.response){
                throw Error ('something_went_wrong');
            }
            // handle error
            const { text } = error.response.data;
            throw Error (text);
        });
};
