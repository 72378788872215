const actions = {
    SUBMIT_PERSONAL_DATA: 'SUBMIT_PERSONAL_DATA',
    SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
    SEND_CODE: 'SEND_CODE',
    CODE_VERIFICATION: 'CODE_VERIFICATION',
    EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
    SUBMIT_ADDRESS: 'SUBMIT_ADDRESS',
    SUBMIT_APPLICATION: 'SUBMIT_APPLICATION',
    GET_CITIES: 'GET_CITIES',
    UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
    submitPersonalData: data => {
        return {
            type: actions.SUBMIT_PERSONAL_DATA,
            data: data
        }
    },
    submitSuccess: data => {
        return {
            type: actions.SUBMIT_SUCCESS,
            data: data
        }
    },
    sendCode: data => {
        return {
            type: actions.SEND_CODE,
            data: data
        }
    },
    verifyCode: data => {
        return {
            type: actions.CODE_VERIFICATION,
            data: data
        }
    },
    verifyEmail: data => {
        return {
            type: actions.EMAIL_VERIFICATION,
            data: data
        }
    },
    submitAddress: data => {
        return {
            type: actions.SUBMIT_ADDRESS,
            data: data
        }
    },
    submitApplication: data => {
        return {
            type: actions.SUBMIT_APPLICATION,
            data: data
        }
    },
    getCities: data => {
        return {
            type: actions.GET_CITIES,
            data: data
        }
    },
    uploadDocuments: data => {
        return {
            type: actions.UPLOAD_DOCUMENTS,
            data: data
        }
    }
};

export default actions;