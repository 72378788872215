import {Map} from "immutable";
import actions from './actions';
import cities from "../helpers/cities";


const init_state = new Map({
    ...cities,
    defaultAddress: {
        country: 'Armenia',
        state: 'Երևան',
        city: 'Երևան',
        street: 'Կոմիտաս',
        house: '25',
        appartment: '35'
    },
    statementOptions: [
        {id:1, name:'Էլ.հասցե'},
        {id:2, name:'Փոստային առաքում'},
        {id:3, name:'Բանկային մասնաճյուղ'}
    ]
});


const reducer = (state = init_state, action) => {
    switch (action.type) {
        case actions.SUBMIT_SUCCESS: {
            return state.set([action.data],'success');
        }
        default:
            return state;
    }
};

export default reducer;