import {lazy, Suspense} from 'react';
import {Router, Switch, Route} from "react-router-dom";
import customHistory from "../helpers/history";


const PersonalData = lazy(() => import('../pages/personal-data'));
const UserDocuments = lazy(() => import('../pages/user-documents'));
const PhoneVerification = lazy(() => import('../pages/phone-verification'));
const EmailVerification = lazy(() => import('../pages/email-verification'));
const UserAddress = lazy(() => import('../pages/user-address'));
const LoanPage = lazy(() => import('../pages/loan-page'));
const Message = lazy(() => import('../components/message'));


const Routes = () => {
    return (

        <Router history={customHistory}>
            <Suspense fallback="loading">
                <Switch>
                    <Route exact path="/">
                        <PersonalData/>
                    </Route>
                    <Route path="/user-documents/">
                        <UserDocuments/>
                    </Route>
                    <Route path="/phone-verification/">
                        <PhoneVerification/>
                    </Route>
                    <Route path="/email-verification/">
                        <EmailVerification/>
                    </Route>
                    <Route path="/user-address/">
                        <UserAddress/>
                    </Route>
                    <Route path="/loan-page/">
                        <LoanPage/>
                    </Route>
                    <Route path="/success-page/">
                        <Message/>
                    </Route>
                    <Route path="/failure-page/">
                        <Message/>
                    </Route>

                </Switch>
            </Suspense>
        </Router>
    )
}

export default Routes;