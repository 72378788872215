import {all, call, fork,put, takeLatest,select} from 'redux-saga/effects';
import Api from './api';
import actions from './actions';
import customHistory from "../helpers/history";


const API = new Api();

const getStoreData = (state,reducer) => state.get(reducer);


function* submitPersonalData() {
    yield takeLatest(actions.SUBMIT_PERSONAL_DATA, function* (action) {
        try {
            const { data } = action;
            const response = yield call(API.submitPersonalData, data);
            if (response && response.status === 200) {
                yield put(actions.submitSuccess('personal_data'))
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* sendCode() {
    yield takeLatest(actions.SEND_CODE, function* (action) {
        try {
            const { data } = action;
            const response = yield call(API.sendCode, data);
            if (response && response.status === 200) {
                yield put(actions.submitSuccess('code_send'))
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* verifyCode() {
    yield takeLatest(actions.CODE_VERIFICATION, function* (action) {
        try {
            const { data } = action;
            const response = yield call(API.verifyCode, data);
            if (response && response.status === 200) {
                yield put(actions.submitSuccess('code_verify'));
                customHistory.push('/email-verification/');
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* verifyEmail() {
    yield takeLatest(actions.EMAIL_VERIFICATION, function* (action) {
        try {
            const { data } = action;
            const response = yield call(API.verifyEmail, data);
            if (response && response.status === 200) {
                yield put(actions.submitSuccess('email_verify'));
                customHistory.push('/user-documents/');
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* submitAddress() {
    yield takeLatest(actions.SUBMIT_ADDRESS, function* (action) {
        try {
            let { data } = action;
            if(!data)
            {
                data = yield select(getStoreData,'defaultAddress');
            }
            const response = yield call(API.submitAddress, data);
            if (response && response.status === 200) {
                yield put(actions.submitSuccess('address_submit'))
                customHistory.push('/loan-page/');
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* submitApplication() {
    yield takeLatest(actions.SUBMIT_APPLICATION, function* (action) {
        try {
            let { data } = action;
            const response = yield call(API.submitApplication, data);
            if (response && response.status === 200) {
                yield put(actions.submitSuccess('application_submit'))
                customHistory.push('/success-page/');
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
            customHistory.push('/failure-page/');
        }
    });

}

function* getCities() {
    yield takeLatest(actions.GET_CITIES, function* (action) {
        try {
            let { data } = action;
            const response = yield call(API.getCities, data);
            if (response && response.status === 200) {
                yield put(actions.submitSuccess('application_submit'))
                customHistory.push('/success-page/');
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
            customHistory.push('/failure-page/');
        }
    });

}

function* uploadDocuments() {
    yield takeLatest(actions.UPLOAD_DOCUMENTS, function* (action) {
        try {
            let { data } = action;
            const response = yield call(API.uploadDocuments, data);
            if (response && response.status === 200) {
                yield put(actions.submitSuccess('upload_documents'))
                customHistory.push('/user-address/');
            }
        } catch (e) {
            const message = {
                title: "Error",
                message: e.message,
                type: "danger"
            };
            console.log(message);
        }
    });

}

function* saga() {
    yield all([
        fork(submitPersonalData),
        fork(sendCode),
        fork(verifyCode),
        fork(verifyEmail),
        fork(submitAddress),
        fork(submitApplication),
        fork(getCities),
        fork(uploadDocuments)
    ]);
}

export default saga;