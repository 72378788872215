const cities = {
    states: [
        {id:1, name:'Երևան'},
        {id:2, name:'Արագածոտն'},
        {id:3, name:'Արարատ'},
        {id:4, name:'Արմավիր'},
        {id:5, name:'Գեղարքունիք'},
        {id:6, name:'Լոռի'},
        {id:7, name:'Կոտայք'},
        {id:8, name:'Շիրակ'},
        {id:9, name:'Սյունիք'},
        {id:10, name:'Վայոց Ձոր'},
        {id:11, name:'Տավուշ'},
    ],
    cities: {
        1: [
            {id: 1, name: 'Երևան'},
            {id: 2, name: 'Ավան'},
            {id: 3, name: 'Արաբկիր'},
            {id: 4, name: 'Դավթաշեն'},
            {id: 5, name: 'Էրեբունի'},
            {id: 6, name: 'Կենտրոն'},
            {id: 7, name: 'Մալաթիա-Սեբաստիա'},
            {id: 8, name: 'Նոր Նորք'},
            {id: 9, name: 'Նորք Մարաշ'},
            {id: 10, name: 'Նուբարաշեն'},
            {id: 11, name: 'Շենգավիթ'},
            {id: 12, name: 'Քանաքեռ Զեյթուն'}
        ],
        2: [
            {id: 1, name: 'Աշտարակ'},
            {id: 2, name: 'Ապարան'},
            {id: 3, name: 'Թալին'}
        ],
        3: [
            {id: 1, name: 'Արտաշատ'},
            {id: 2, name: 'Արարատ'},
            {id: 3, name: 'Մասիս'},
            {id: 4, name: 'Վեդի'}
        ],
        4: [
            {id: 1, name: 'Արմավիր'},
            {id: 2, name: 'Մեծամոր'},
            {id: 3, name: 'Վաղարշապատ'}
        ],
        5: [
            {id: 1, name: 'Սևան'},
            {id: 2, name: 'Ճամբարակ'},
            {id: 3, name: 'Գավառ'},
            {id: 4, name: 'Մարտունի'},
            {id: 5, name: 'Վարդենիս'}
        ],
        6: [
            {id: 1, name:'Վանաձոր'},
            {id: 2, name:'Ալավերդի'},
            {id: 3, name:'Ախթալա'},
            {id: 4, name:'Թումանյան'},
            {id: 5, name:'Շամլուղ'},
            {id: 6, name:'Սպիտակ'},
            {id: 7, name:'Ստեփանավան'},
            {id: 8, name:'Տաշիր'}
        ],
        7: [
            {id: 1, name: 'Աբովյան'},
            {id: 2, name: 'Բյուրեղավան'},
            {id: 3, name: 'Եղվարդ'},
            {id: 4, name: 'Նոր Հաճն'},
            {id: 5, name: 'Չարենցավան'},
            {id: 6, name: 'Ծաղկաձոր'}
        ],
        8: [
            {id: 1, name: 'Գյումրի'},
            {id: 2, name: 'Արթիկ'},
            {id: 3, name: 'Մարալիկ'}
        ],
        9: [
            {id: 1, name: 'Կապան'},
            {id: 2, name: 'Գորիս'},
            {id: 3, name: 'Դաստակերտ'},
            {id: 4, name: 'Սիսիան'},
            {id: 5, name: 'Քաջարան'},
            {id: 5, name: 'Ագարակ'},
            {id: 6, name: 'Մեղրի'}
        ],
        10: [
            {id: 1, name: 'Ջերմուկ'},
            {id: 2, name: 'Վայք'},
            {id: 3, name: 'Եղեգնաձոր'}
        ],
        11: [
            {id: 1, name: 'Իջևան'},
            {id: 2, name: 'Նոյեմբերյան'},
            {id: 3, name: 'Բերդ'},
            {id: 4, name: 'Դիլիջան'},
            {id: 5, name: 'Այրում'}
        ]
    }
};

export default cities;