import './App.css';
import Header from "./components/header";
import Routes from "./routes";

function App() {
    return (
        <div className="App">
            <Header/>
            <div className="content">
                <div className="content_inner custom_container"
                     style={{backgroundImage: `url(/resources/images/decor.svg)`}}>
                    <Routes/>
                </div>
            </div>
        </div>
    );
}

export default App;
